<template>
  <moe-page title="N元任选">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">N元任选</div>
            <div class="font-14">可以按需圈选商品范围，设置不同优惠力度。优惠券以电子券为载体，满足商家多样化营销诉求，提升店铺转化率。</div>
          </div>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="nYuanOptionalSearch">
        <el-form-item label="活动状态">
          <moe-select type="optionalStateList" v-model="nYuanOptionalParams.state" placeholder="选择活动状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="nYuanOptionalParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="nYuanOptionalParams.id" @input="(value) => nYuanOptionalParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([nYuanOptionalParams.startTime, nYuanOptionalParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="nYuanOptionalTableRef" url="/shop/optional/list" :params="nYuanOptionalParams" :number-show="false">

        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/nYuanOptional/add')">新增活动</el-button>

        <el-table-column label="活动信息" min-width="150">
          <template slot-scope="{ row }">
            <div>{{ row.name }}</div>
            <div>活动编号:{{ row.id }}</div>
          </template>
        </el-table-column>

        <el-table-column label="优惠方式" min-width="150">
          <template slot-scope="{ row }">
            {{ `${row.money} 元 任选 ${row.number} 件` }}
          </template>
        </el-table-column>

        <el-table-column label="商品数量" min-width="100" prop="total" />

        <el-table-column label="使用时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>

        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getOptionalStateListColor(row.state)" slot-scope="{ row }">
            {{ $moe_format.getOptionalStateList(row.state) }}
          </div>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="350">
          <div slot-scope="{ row }">
            <el-button v-if="!['PENDING'].includes(row.state)" type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/nYuanOptional/detail`, { id: row.id })">查看</el-button>
            <el-button v-if="!['FINISHED', 'CLOSED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/nYuanOptional/add`, { id: row.id })">编辑</el-button>
            <el-button v-if="['IN_PROGRESS'].includes(row.state)" type="warning" size="mini" icon="el-icon-video-pause" @click="handlePause(row)">暂停</el-button>
            <el-button v-if="['PAUSED', 'FINISHED'].includes(row.state)" type="info" size="mini" icon="el-icon-video-play" @click="handleRestart(row)">重启</el-button>
            <el-button v-if="!['PENDING', 'CLOSED'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
            <el-button v-if="['NOT_STARTED'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>

    <moe-dialog :autoHeight="true" :show="showDialog" title="重启活动" width="30%" @close="handleDialogClose()">
      <moe-form
        ref="restartForm"
        :showBack="false"
        :model="restartParams"
        :rules="rules">
        <div class="mb-20 df aic color-info">
          <div class="mr-10"><moe-icon name="reminder" size="26px"></moe-icon></div>
          <div class="font-16">活动时间已过期，请重新设置活动时间并提交重启</div>
        </div>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="活动时间开始时间"
            end-placeholder="活动时间结束时间"
            :picker-options="pickerOptions"
            @change="(datetime) => ([restartParams.startTime, restartParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-form>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageNYuanOptionalList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      nYuanOptionalParams: {
        pageNum: 1,
        pageSize: 10,
        state: '',
        name: '',
        id: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
      showDialog: false,
      restartParams: {
        id: '',
        startTime: '',
        endTime: ''
      },
      rules: {
        startTime: verify.isEmpty('请选择活动时间'),
      },
      pickerOptions: {
        disabledDate(currentDate) {
          // 获取当前日期
          const today = new Date();
          // 将时间设置为0时0分0秒
          today.setHours(0, 0, 0, 0);

          // 如果当前日期小于今天，则禁用选择
          return currentDate < today;
        }
      },
    }
  },
  methods: {
    nYuanOptionalSearch(isSearch) {
      if (!isSearch) {
        this.nYuanOptionalParams = {
          pageNum: 1,
          pageSize: 10,
          state: '',
          name: '',
          id: '',
          startTime: '',
          endTime: '',
        }
        this.datetime = [];
      }

      this.$refs['nYuanOptionalTableRef'].searchData();
    },
    /** 暂停 */
    handlePause({ id, name }) {
      this.$moe_layer.confirm(`您确定要暂停当前选项"${name}"吗？`, () => {
        this.$moe_api.NYUANOPTIONAL_API.pauseOptional({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('暂停成功', {
              completionHandler: () => {
                this.nYuanOptionalSearch(true);
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    handleDialogClose() {
      this.showDialog = false;
      this.$refs['restartForm'].resetFields();
    },
    handleConfirm() {
      this.$refs['restartForm'].validate(() => {
        this.$moe_api.NYUANOPTIONAL_API.restartOptional(this.restartParams).then((data) => {
          if (data.code == 200) {
            this.handleDialogClose();
            this.$moe_msg.success('重启成功', {
              completionHandler: () => {
                this.nYuanOptionalSearch(true);
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      })
    },
    /** 重启 */
    handleRestart({ id, name, endTime }) {
      if (this.$moe_time.dateBefore({ startDate: endTime })) {
          this.showDialog = true;
          this.restartParams.id = id;
      } else {
        this.$moe_layer.confirm(`您确定要重启当前选项"${name}"吗？`, () => {
          this.$moe_api.NYUANOPTIONAL_API.restartOptional({ id }).then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('重启成功', {
                completionHandler: () => {
                  this.nYuanOptionalSearch(true);
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          })
        });
      }
    },
    /** 关闭 */
    handleClose({ id, name }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, () => {
        this.$moe_api.NYUANOPTIONAL_API.closeOptional({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('关闭成功', {
              completionHandler: () => {
                this.nYuanOptionalSearch(true);
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /** 删除 */
    handleDelete({ id, name }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.NYUANOPTIONAL_API.delOptional({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功', {
              completionHandler: () => {
                this.nYuanOptionalSearch(true);
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    }
  }
}
</script>

<style lang="scss">
</style>